import axios from "axios";
import { SERVER } from "../constants/config";
import jwtDecode from 'jwt-decode'
import { Form } from "reactstrap";
import { moment } from 'moment'

import FileDownload from 'js-file-download';

const sign = require('jwt-encode');
const secret = "ts6nJu7TGes*og$C63NKR412zVhtXsiw5Zd$LC7tk$B^6%WXU1";
const data = {
  type: 'browser',
  name: 'HRAI',
  time: Math.floor(Date.now() / 1000)
};
const jwt = sign(data, secret);

export const fetchCompany = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: jwt,
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };    
  return dispatch => {
    

        const decoded = jwtDecode(localStorage.jwtToken)
        const is_admin = decoded.admin_system

        const params = { is_admin }
        axios
          .get(`${SERVER}/role/company/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const insertCompany = (company_code, company_name, company_description,company_username, company_pass, company_device, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const is_admin = decoded.admin_system
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('is_admin', is_admin) 
  formData.append('company_code', company_code)
  formData.append('company_name', company_name)
  formData.append('company_description',company_description)
  formData.append('company_username', company_username)
  formData.append('company_pass', company_pass)
  formData.append('company_device', company_device)
  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/company/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const deleteCompany = (company_code, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const is_admin = decoded.admin_system
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('is_admin', is_admin)
  formData.append('company_code', company_code)

  return dispatch => {
    axios
      .post(`${SERVER}/role/company/delete`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchOffice = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };

  return dispatch => {

    
        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const office_list_code = decoded.office_list_code
        const dept_list_code = decoded.dept_list_code

        const params = { company_code, is_hr, office_list_code, dept_list_code }

        axios
          .get(`${SERVER}/role/office/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);

  };

}

export const fetchOffice_all = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = 1
        const office_list_code = decoded.office_list_code

        const params = { company_code, is_hr, office_list_code }

        axios
          .get(`${SERVER}/role/office/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);

  };
}

export const insertOffice = (office_code, office_name, office_description, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr= decoded.is_hr
  const office_list_code = decoded.office_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('office_code', office_code)
  formData.append('office_name', office_name)
  formData.append('office_description',office_description)
  formData.append('company_code', company_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/office/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const deleteOffice = (office_code,callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr= decoded.is_hr
  const office_list_code = decoded.office_list_code
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)

  return dispatch => {
    axios
      .post(`${SERVER}/role/office/delete`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchPosition = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };

  return dispatch => {
    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const office_list_code = decoded.office_list_code
        const dept_list_code = decoded.dept_list_code

        // const params = { company_code }
        const params = { company_code, is_hr, office_list_code, dept_list_code }


        axios
          // .get(`${SERVER}/role/position/fetch`, {
          //   params: params,
          //   
          // })
          .get(`${SERVER}/role/position/fetchv2`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };
}

export const insertPosition = (position_code, position_name, position_description, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('position_code', position_code)
  formData.append('position_name', position_name)
  formData.append('position_description',position_description)
  formData.append('company_code', company_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/position/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const insertPositionv2 = (position_code, position_name, position_description, office_code, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('position_code', position_code)
  formData.append('position_name', position_name)
  formData.append('position_description',position_description)
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)


  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/position/insertv2`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const deletePosition = (position_code,callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('company_code', company_code)
  formData.append('position_code', position_code)

  return dispatch => {
    axios
      .post(`${SERVER}/role/position/delete`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const deletePositionv2 = (position_code,callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('company_code', company_code)
  formData.append('position_code', position_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)


  return dispatch => {
    axios
      .post(`${SERVER}/role/position/deletev2`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const fetchDepartment = (callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/department/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const fetchDepartmentGuest = (callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };
  return dispatch => {
    axios
      .get(`${SERVER}/department/guest/fetch`, {
        params: {
          company_code: '4567',
        },
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  }
}

export const fetchDepartmentByCode = (department_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/department/fetchbycode`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const fetchExamTemp = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/exam/fetchtemp`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const fetchExam = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/exam/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}


export const insertExam = (department_code, office_code, student_code, exam_name, 
  exam_score, exam_date, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('student_code', student_code)
  formData.append('exam_name',exam_name)
  formData.append('exam_score',exam_score)
  formData.append('exam_date',exam_date)  
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)

  console.log(department_code, office_code, student_code, exam_name, 
    exam_score, exam_date, company_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/exam/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const updateExam = (department_code, office_code, student_code, 
  exam_id, exam_name, exam_score, exam_date, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('student_code', student_code)
  formData.append('exam_id',exam_id)
  formData.append('exam_name',exam_name)
  formData.append('exam_score',exam_score)
  formData.append('exam_date',exam_date)  
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)


  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/exam/update`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchEva = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/eva/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}


export const insertEva = (department_code, office_code, student_code, 
  evaluate_comment, evaluate_date, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('student_code', student_code)  
  formData.append('evaluate_comment',evaluate_comment)
  formData.append('evaluate_date',evaluate_date)  
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)

  // console.log(department_code, office_code, student_code, 
  //   evaluate_comment, evaluate_date, company_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/eva/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const fetchFee = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/fee/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const downloadTimesheet = (department_code, department_name, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };


  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const EditBy = decoded.employee_code
        const company_code = decoded.company_code
        

        const formData = new FormData()
        formData.append('EditBy', EditBy)
        formData.append('company_code', company_code)
        formData.append('department_code', department_code)
        formData.append('office_code', office_code)
        formData.append('department_name', department_name)
        

        const newDate = new Date()


        axios
          .post(`${SERVER}/role/timesheet/download`, formData, {
            // params: params,
            
            responseType: 'blob'
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            FileDownload(res.data, 'Điểm_Danh.xlsx' );
            callback(res.data);
          })
          .catch(console.log);
  };

}


export const insertFee = (department_code, office_code, student_code, 
  note, fee_date,fee_status,fee_type,fee_time_name,fee_amount, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('student_code', student_code)  
  formData.append('note',note)
  formData.append('fee_date',fee_date)  
  formData.append('fee_status',fee_status)  
  formData.append('fee_type',fee_type)  
  formData.append('fee_time_name',fee_time_name)    
  formData.append('fee_amount',fee_amount)      
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)

  // console.log(department_code, office_code, student_code, 
  //   evaluate_comment, evaluate_date, company_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/fee/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchFeeTime = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/fee_time/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}


export const insertFeeTime = (department_code, office_code, fee_time_name, 
  fee_time_start,fee_time_end,fee_time_amount, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('fee_time_name', fee_time_name)  
  formData.append('fee_time_start',fee_time_start)  
  formData.append('fee_time_end',fee_time_end)  
  formData.append('fee_time_amount',fee_time_amount)  
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)

  // console.log(department_code, office_code, student_code, 
  //   evaluate_comment, evaluate_date, company_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/fee_time/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchTimesheetByCode = (department_code, office_code, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {

    

        const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = decoded.company_code
        const is_hr = decoded.is_hr
        const dept_list_code = decoded.dept_list_code
        const office_list_code = decoded.office_list_code

        const params = { company_code, department_code, office_code, is_hr, dept_list_code, office_list_code }

        axios
          .get(`${SERVER}/role/timesheet/fetchbycode`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const insertTimesheet = (department_code, office_code, employee_code,
  leave_score, leave_date, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr = decoded.is_hr
  const office_list_code = decoded.office_list_code
  const dept_list_code = decoded.dept_list_code
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('employee_code', employee_code)
  formData.append('leave_score',leave_score)
  formData.append('leave_date',leave_date)  
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append('office_list_code', office_list_code)
  formData.append('dept_list_code', dept_list_code)

  console.log(department_code, office_code, employee_code,
    leave_score, leave_date)

  return dispatch => {
    axios
      .post(`${SERVER}/role/timesheet/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchDepartmentByEmp = (CompanyCode, callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };  

  return dispatch => {    

    
        
        const company_code = CompanyCode
        const is_hr = 0
        const dept_list_code = 'None'
        const params = { company_code, is_hr, dept_list_code }

        axios
          .get(`${SERVER}/role/department/fetch`, {
            params: params,
            
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };

}

export const fetchDepartment_all = (callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };

  return dispatch => {
    

      const decoded = jwtDecode(localStorage.jwtToken)
      const company_code = decoded.company_code
      const is_hr = 1
      const dept_list_code = decoded.dept_list_code
      const office_list_code = decoded.office_list_code

      const params = { company_code, is_hr, dept_list_code, office_list_code }

      axios
        .get(`${SERVER}/role/department/fetch`, {
          params: params,
          
        })
        .then(res => {
          // res.data.sizePerPage = sizePerPage
          // res.data.page = page
          // console.log(res.data)
          callback(res.data);
        })
        .catch(console.log);
      
  };

}

export const insertDepartment = (department_code, 
  department_name, 
  department_description, 
  office_code,
  shift_start_date,
  shift_end_date,
  shift_code_1,
  shift_start_time_1,
  shift_end_time_1,
  shift_code_2,
  shift_start_time_2,
  shift_end_time_2,
  shift_code_3,
  shift_start_time_3,
  shift_end_time_3,
  shift_code_4,
  shift_start_time_4,
  shift_end_time_4,
  shift_code_5,
  shift_start_time_5,
  shift_end_time_5,
  callback ) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };

  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr= decoded.is_hr
  const dept_list_code = decoded.dept_list_code
  const office_list_code = decoded.office_list_code
  const formData = new FormData()  
  formData.append('EditBy', EditBy)
  formData.append('department_code', department_code)
  formData.append('department_name', department_name)
  formData.append('department_description',department_description)
  formData.append('company_code', company_code)
  formData.append('office_code', office_code)
  formData.append('is_hr', is_hr)
  formData.append("start_date",shift_start_date)
  formData.append("end_date",shift_end_date)
  formData.append("shift_code_1",shift_code_1)
  formData.append("shift_start_time_1",shift_start_time_1)
  formData.append("shift_end_time_1",shift_end_time_1)
  formData.append("shift_code_2",shift_code_2)
  formData.append("shift_start_time_2",shift_start_time_2)
  formData.append("shift_end_time_2",shift_end_time_2)
  formData.append("shift_code_3",shift_code_3)
  formData.append("shift_start_time_3",shift_start_time_3)
  formData.append("shift_end_time_3",shift_end_time_3)
  formData.append("shift_code_4",shift_code_4)
  formData.append("shift_start_time_4",shift_start_time_4)
  formData.append("shift_end_time_4",shift_end_time_4)
  formData.append("shift_code_5",shift_code_5)
  formData.append("shift_start_time_5",shift_start_time_5)
  formData.append("shift_end_time_5",shift_end_time_5)
  formData.append('dept_list_code', dept_list_code)
  formData.append('office_list_code', office_list_code)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/department/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const deleteDepartment = (department_code,callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  const is_hr= decoded.is_hr
  const dept_list_code = decoded.dept_list_code
  const office_list_code = decoded.office_list_code
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('company_code', company_code)
  formData.append('department_code', department_code)
  formData.append('is_hr', is_hr)
  formData.append('dept_list_code', dept_list_code)
  formData.append('office_list_code', office_list_code)

  return dispatch => {
    axios
      .post(`${SERVER}/role/department/delete`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const fetchPlace = (callback) => {
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   Authorization: localStorage.getItem("jwtToken"),
  //   fingerprint: "123456",
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  // };

  return dispatch => {
    

      const decoded = jwtDecode(localStorage.jwtToken)
      const company_code = decoded.company_code
      
      const params = {company_code}
      
      axios
        .get(`${SERVER}/role/place/fetch`, {
          params: params,
          
        })
        .then(res => {
          // res.data.sizePerPage = sizePerPage
          // res.data.page = page
          // console.log(res.data)
          callback(res.data);
        })
        .catch(console.log);
     
  };

}

export const insertPlace = (place_code, place_name, place_address, lat,long, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code

  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('place_code', place_code)
  formData.append('place_name',place_name)
  formData.append('place_address', place_address)
  formData.append('company_code', company_code)
  formData.append('EditBy', EditBy)
  formData.append('lat', lat)
  formData.append('long',long)

  //console.log(formData)

  return dispatch => {
    axios
      .post(`${SERVER}/role/place/insert`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}


export const deletePlace = (place_code,callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const decoded = jwtDecode(localStorage.jwtToken)
  const EditBy = decoded.employee_code
  const company_code = decoded.company_code
  
  const formData = new FormData()
  formData.append('EditBy', EditBy)
  formData.append('company_code', company_code)
  formData.append('place_code', place_code)

  return dispatch => {
    axios
      .post(`${SERVER}/role/place/delete`, formData, {
        //params: params,
        
      })
      .then(res => {
        // res.data.sizePerPage = sizePerPage
        // res.data.page = page
        // console.log(res.data)
        callback(res.data);
      })
      .catch(console.log);
  };

}

export const fetchFeedback = (department_code, callback) => {
  return dispatch => {
    

        axios
          .get(`${SERVER}/feedback/fetch`, {
            
            params: {
              department_code: department_code,
            }
          })
          .then(res => {
            // res.data.sizePerPage = sizePerPage
            // res.data.page = page
            // console.log(res.data)
            callback(res.data);
          })
          .catch(console.log);
  };
}

export const insertFeedback = (params, callback) => {
  return dispatch => {
    

        // const decoded = jwtDecode(localStorage.jwtToken)
        const company_code = '4567'
        // const EditBy = decoded.employee_code

        const formData = new FormData()
        formData.append('created_by', "Guest")
        formData.append('company_code', company_code)
        formData.append('department_code', params.department_code)
        formData.append('feedback_teacher', params.feedback_teacher)
        formData.append('comment_teacher', params.comment_teacher)
        formData.append('suggestion', params.suggestion)

        axios
          .post(`${SERVER}/feedback/insert`, formData, {
            
          })
          .then(res => {
            callback(res.data);
          })
          .catch(console.log);
  };
}