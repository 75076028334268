import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthorize } from '../../utils/isLogin'
import jwtDecode from 'jwt-decode'

const loginGuard = ({ component: Component, path }) => {
  //console.log(path)
  return (
    //    <Route to={path} component={Component} />
    
    <Route
      to={path}
      render={routeProps => {
        // console.log(routeProps.location.pathname)            
        const user = localStorage.getItem("userLogin")
        if (user) {
          const company = JSON.parse(user)['company']
          if (company === 'PM') {

            const pathname = routeProps.location.pathname
            // console.log(isAuthorize(pathname))
            // console.log(pathname)
            if (isAuthorize(pathname)) {
              return <Component {...routeProps} />;
            }
            else {
              // const decoded = jwtDecode(localStorage.jwtToken)
              // const employee_code = decoded.employee_code
            
              alert('Bạn không có quyền truy cập !')
              return <Redirect to={"/login"} />
            }
          }
        }
        alert('Vui lòng đăng nhập !');
        return <Redirect to="/Home" />
      }}
    />
  );
};

export default loginGuard;
